













































































import { Vue, Component } from 'vue-property-decorator'
import { IS_LOGGED_IN, SIGN_OUT, USER_ATTRIBUTE } from '@/store/auth'
import { IS_VISIBLE, MESSAGE, SET_VISIBLE } from '@/store/snackbar'
import { PRODUCT_NAME, MARKETING_URL } from '@/modules/config'

@Component({
  head: {
    title() {
      return {
        inner: 'Dashboard',
      }
    },
    style: [
      {
        type: 'text/css',
        inner: `.v-main {
          background: url('/img/seller-background.svg');
          background-size: 650%;
          background-position: 5.5% 0%;
          background-attachment: fixed;
        }`,
      },
    ],
  },
})
export default class DefaultLayout extends Vue {
  title = PRODUCT_NAME
  MARKETING_URL = MARKETING_URL
  showAccountMenu = false
  accountMenuItems = [
    {
      text: 'Reset password',
      action: async () => {
        const email = await this.$store.dispatch(USER_ATTRIBUTE, 'email')
        this.showAccountMenu = false
        this.$router.push({
          path: '/forgot-password',
          query: {
            email,
          },
        })
      },
    },
    {
      text: 'Sign out',
      action: async () => {
        await this.$store.dispatch(SIGN_OUT)
        this.showAccountMenu = false
        this.$router.push('/sign-in')
      },
    },
  ]

  get showAccountButton() {
    this.$store.state.user
    this.$store.state.session
    return this.$store.getters[IS_LOGGED_IN]
  }

  get isSnackbarVisible() {
    return this.$store.getters[IS_VISIBLE]
  }

  set isSnackbarVisible(flag: boolean) {
    this.$store.commit(SET_VISIBLE, flag)
  }

  get snackbarMessage() {
    return this.$store.getters[MESSAGE]
  }

  get buildTime() {
    return new Date(process.env.VUE_APP_BUILD_TIME!)
  }

  mounted() {
    console.log(
      `===Build info===\nBuild time: ${this.buildTime}\nBuild number: ${process.env.VUE_APP_BUILD_NUMBER}\nBuild environment: ${process.env.NODE_ENV}`,
    )
  }

  beforeRouteLeave() {
    this.showAccountMenu = false
  }
}
