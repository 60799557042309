// Unfortunately we cannot use Intl.PluralRules due to:
// https://github.com/home-assistant/frontend/issues/7551

import Address from '@/types/Address'

export function ordinal(n: number) {
  const digits = [n % 10, n % 100],
    ordinals = ['st', 'nd', 'rd', 'th'],
    oPattern = [1, 2, 3, 4],
    tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
  return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
    ? n + ordinals[digits[0] - 1]
    : n + ordinals[3]
}

export function listAsString(list: string[]) {
  if (list.length === 1) {
    return list[0]
  }
  return (
    list.slice(0, list.length - 1).join(', ') + ' and ' + list[list.length - 1]
  )
}

export function formatAddress(
  line1: string | Address,
  suburb?: string,
  state?: string,
  postalCode?: string,
) {
  if (suburb && state && postalCode)
    return `${line1}, ${suburb} ${state} ${postalCode}`
  if (line1 instanceof String)
    throw new TypeError('suburb, state and postalCode must be provided')
  const address = line1 as Address
  return `${address.line1}, ${address.suburb} ${address.state} ${address.postalCode}`
}

const currencyCode = 'AUD'

const fractionalPriceFormatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: currencyCode,
  maximumFractionDigits: 2,
})

const priceFormatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: currencyCode,
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
})

enum CurrencyCodePosition {
  START = -1,
  END = 1,
  NONE = 0,
}

export function formatAUD(
  amount: number,
  currencyCodePosition: CurrencyCodePosition = CurrencyCodePosition.NONE,
  decimal = false,
) {
  const formatter =
    !Number.isInteger(amount) || decimal
      ? fractionalPriceFormatter
      : priceFormatter
  return `${
    currencyCodePosition === CurrencyCodePosition.START
      ? `${currencyCode} `
      : ''
  }${formatter.format(amount)}${
    currencyCodePosition === CurrencyCodePosition.END ? ` ${currencyCode}` : ''
  }`
}

export function removeSpaces(value: string) {
  return value.replaceAll(' ', '')
}
