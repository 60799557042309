import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import autoRoutes from 'vue-auto-routing'
import { createRouterLayout } from 'vue-router-layout'
import ErrorLayout from '@/layouts/error.vue'
import StylesheetPage from '@/pages/-stylesheet.vue'
import DefaultLayout from '@/layouts/default.vue'
import store from './store'
import { IS_LOGGED_IN, IS_SESSION_VALID } from '@/store/auth'

Vue.use(Router)

const RouterLayout = createRouterLayout(layout => {
  return import('@/layouts/' + layout + '.vue')
})

const routes: RouteConfig[] = [
  {
    path: '/',
    component: RouterLayout,
    children: autoRoutes,
  },
]
if (process.env.NODE_ENV === 'development')
  routes.push({
    path: '/stylesheet',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: StylesheetPage,
      },
    ],
  })
routes.push({ path: '*', component: ErrorLayout })

const router = new Router({
  mode: 'history',
  base: '/',
  routes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  // redirect root to sign in
  if (to.path === '/') {
    next('/sign-in')
    return
  }

  if (store.getters[IS_LOGGED_IN] && store.getters[IS_SESSION_VALID]) {
    // do not show confirmation pages if logged in
    if (
      ['/unconfirmed', '/confirm', '/sign-in'].some(v => to.path.startsWith(v))
    ) {
      // TODO: redirect buyers somewhere more appropriate
      next('/releases')
      return
    }
  } else if (to.path.startsWith('/releases')) {
    // releases routes require authentication
    next('/sign-in')
    return
  }

  if (/^\/[aA]\//.test(to.path)) {
    // a is shorthand for the /applications route
    next({
      path: to.path.replace('/a/', '/applications/'),
      query: to.query,
    })
  } else if (to.path === '/error') {
    // set title for error layout
    Vue.nextTick(() => {
      document.title = 'Error'
    })
    next()
  } else next()
})

export default router
