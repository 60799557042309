export const PRODUCT_NAME = 'Release.Me'
export const MARKETING_URL = process.env.VUE_APP_MARKETING_URL
export const CONTACT_EMAIL = 'contact@release.me'

export const states = [
  {
    postal: 'NSW',
    name: 'New South Wales',
  },
  {
    postal: 'VIC',
    name: 'Victoria',
  },
  {
    postal: 'QLD',
    name: 'Queensland',
  },
  {
    postal: 'SA',
    name: 'South Australia',
  },
  {
    postal: 'WA',
    name: 'Western Australia',
  },
  {
    postal: 'TAS',
    name: 'Tasmania',
  },
  {
    postal: 'NT',
    name: 'Northern Territory',
  },
  {
    postal: 'ACT',
    name: 'Australian Capital Territory',
  },
]
