<template>
  <div class="pb-16" style="min-height: 100vh; background-color: #F6F6F6">
    <v-card flat tile class="pa-8 pb-16" color="#F6F6F6">
      <v-window v-model="step">
        <v-window-item>
          <p class="text-h2">Typography</p>
          <v-row class="fill-height" align="start" justify="center">
            <v-col cols="6">
              <span class="overline">Desktop</span>
              <v-divider class="mb-8 mt-2" />
              <p class="text-h1">Heading 1</p>
              <p class="text-h2">Heading 2</p>
              <p class="text-h3">Heading 3</p>
              <p class="text-h4">Heading 4</p>
              <p class="text-h5">Heading 5</p>
              <p class="body-1">body 1</p>
              <p class="body-2">body-2</p>
              <p class="overline">overline</p>
              <p class="caption">caption</p>
              <p class="overline-small">overline small</p>
              <p class=""></p>
            </v-col>
            <v-col cols="6" class="stylesheet-mobile">
              <span class="overline">Mobile</span>
              <v-divider class="mb-8 mt-2" />
              <p class="text-h1">Heading 1</p>
              <p class="text-h2">Heading 2</p>
              <p class="text-h3">Heading 3</p>
              <p class="text-h4">Heading 4</p>
              <p class="text-h5">Heading 5</p>
              <p class="body-1">body 1</p>
              <p class="body-2">body-2</p>
              <p class="overline">overline</p>
              <p class="caption">caption</p>
              <p class="overline-small">overline small</p>
              <p class=""></p>
            </v-col>
          </v-row>
        </v-window-item>
        <v-window-item>
          <p class="text-h2">Colours</p>
          <span class="overline">neutral colours</span>
          <v-divider class="mb-8 mt-2" />
          <div style="color: #454545;">
            <div class="d-flex justify-space-between grey-silver pa-4 mb-4">
              <span>neutral / silver-sand / #C4C7CD</span
              ><span>grey-silver</span>
            </div>
            <div class="d-flex justify-space-between grey-cultured pa-4 mb-4">
              <span>neutral / cultured / #DFE1E4</span
              ><span>grey-cultured</span>
            </div>
            <div class="d-flex justify-space-between grey-light pa-4 mb-4">
              <span>neutral / light-background / #FAFAFA</span
              ><span>grey-light</span>
            </div>
            <div class="d-flex justify-space-between grey-background pa-4 mb-4">
              <span>neutral / grey-background / #F5F5F5</span
              ><span>grey-background</span>
            </div>
            <div class="d-flex justify-space-between white pa-4 mb-4">
              <span>neutral / white / #ffffff</span><span>white</span>
            </div>
          </div>
          <span class="overline">primary colours</span>
          <v-divider class="mb-8 mt-2" />
          <div
            class="d-flex justify-space-between primary white--text pa-4 mb-4"
          >
            <span>neutral / space-cadet / #222B45</span><span>primary</span>
          </div>
          <div
            class="d-flex justify-space-between primary-independence white--text pa-4 mb-4"
          >
            <span>neutral / independence / #3D455C</span><span>secondary</span>
          </div>
          <div
            class="d-flex justify-space-between primary-coral white--text pa-4 mb-4"
          >
            <span>neutral / dark-coral / #727C8E</span><span>accent</span>
          </div>
          <span class="overline">alert/notification colours</span>
          <v-divider class="mb-8 mt-2" />
          <div
            class="d-flex justify-space-between success white--text pa-4 mb-4"
          >
            <span>notification / success / #08BE51</span><span>success</span>
          </div>
          <div
            class="d-flex justify-space-between success-light white--text pa-4 mb-4"
          >
            <span>notification / success-light / #C7EAD6</span
            ><span>success-light</span>
          </div>
          <div
            class="d-flex justify-space-between accent-aquamarine primary--text pa-4 mb-4"
          >
            <span>accent / aquamarine / #64D0AD</span
            ><span>accent-aquamarine</span>
          </div>
          <div
            class="d-flex justify-space-between accent-aquamarine primary--text pa-4 mb-4"
          >
            <span>accent / turquoise / #65DACC</span
            ><span>accent-turquoise</span>
          </div>
          <div
            class="d-flex justify-space-between accent-pine primary--text pa-4 mb-4"
          >
            <span>accent / pine-green-light / #378B85</span
            ><span>accent-pine-light</span>
          </div>
          <div
            class="d-flex justify-space-between accent-pine primary--text pa-4 mb-4"
          >
            <span>accent / pine-green / #006E66</span><span>accent-pine</span>
          </div>
          <div class="d-flex justify-space-between error white--text pa-4 mb-4">
            <span>notification / error / #FB7181</span><span>error</span>
          </div>
          <div
            class="d-flex justify-space-between error-light white--text pa-4 mb-4"
          >
            <span>notification / error-light / #F9DFE2</span
            ><span>error-light</span>
          </div>
          <div
            class="d-flex justify-space-between warning white--text pa-4 mb-4"
          >
            <span>notification / dark-coral / #FFBA49</span><span>warning</span>
          </div>
          <div
            class="d-flex justify-space-between info primary--text pa-4 mb-4"
          >
            <span>notification / info / #96BDFF</span><span>info</span>
          </div>
          <div
            class="d-flex justify-space-between info-light primary--text pa-4 mb-4"
          >
            <span>notification / info-light / #E6EEFA</span
            ><span>info-light</span>
          </div>
        </v-window-item>
        <v-window-item>
          <p class="text-h2">Buttons</p>
          <span class="overline">Variants</span>
          <v-divider class="mb-8 mt-2" />
          <v-btn class="mb-4" color="primary">primary button</v-btn> <br />
          <v-btn class="mb-4" outlined>outlined button</v-btn> <br />
          <v-btn class="mb-4" color="white"
            ><span class="mr-4">download button</span>
            <v-icon>$download</v-icon></v-btn
          >
          <br />
          <v-btn text>Text button</v-btn>
          <br />
          <span class="overline">Disabled</span>
          <v-divider class="mb-8 mt-2" />
          <v-btn disabled class="mb-4" color="primary">primary button</v-btn>
          <br />
          <v-btn disabled class="mb-4" outlined>outlined button</v-btn> <br />
          <v-btn disabled class="mb-4" color="white"
            ><span class="mr-4">download button</span>
            <v-icon>$download</v-icon></v-btn
          >
          <br />
          <v-btn disabled text>Text button</v-btn>
        </v-window-item>
        <v-window-item>
          <p class="text-h2">Inputs</p>
          <span class="overline">Text field</span>
          <v-divider class="mb-8 mt-2" />
          <v-row>
            <v-col xs="12" sm="6">
              <v-text-field solo label="Placeholder" outlined />
              <v-text-field solo label="Placeholder" disabled />
              <v-text-field
                solo
                outlined
                label="Placeholder"
                :error-messages="['Error text']"
              />
              <v-text-field
                solo
                label="Placeholder"
                outlined
                prepend-inner-icon="$box"
              />
            </v-col>
            <v-col xs="12" sm="6">
              <v-text-field label="Placeholder" />
              <v-text-field label="Placeholder" disabled />
              <v-text-field
                label="Placeholder"
                :error-messages="['Error text']"
              />
              <v-text-field label="Placeholder" prepend-inner-icon="$box" />
            </v-col>
          </v-row>
          <span class="overline">dropdown</span>
          <v-divider class="mb-8 mt-2" />
          <v-row>
            <v-col xs="12" sm="6">
              <v-select
                flat
                outlined
                background-color="white"
                value="Default"
                :items="['Default', 'Something else']"
              />
            </v-col>
          </v-row>
          <span class="overline">checkboxes &amp; radio buttons</span>
          <v-divider class="mb-8 mt-2" />
          <v-row>
            <v-col xs="12" md="5">
              <v-checkbox
                v-model="checked"
                :label="checked ? 'Checked' : 'Unchecked'"
              />
            </v-col>
            <v-col xs="12" md="5">
              <v-radio-group :value="true">
                <v-radio label="Checked" :value="true" class="mr-16" />
                <v-radio label="Unchecked" :value="false" />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </v-card>

    <v-bottom-navigation v-model="step" fixed>
      <v-btn
        class="v-btn--minimal"
        v-for="n in length"
        :value="n - 1"
        :key="`btn-${n}`"
      >
        <span>{{ buttonName[n - 1] }}</span>

        <v-icon v-if="buttonSymbol[n - 1].startsWith('$')">{{
          buttonSymbol[n - 1]
        }}</v-icon>
        <span v-else>{{ buttonSymbol[n - 1] }}</span>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  layout: 'stylesheet',
  name: 'stylesheet',
  head: {
    title: {
      inner: 'Stylesheet',
    },
  },
  data: () => ({
    length: 4,
    step: 0,
    checked: false,
    buttonSymbol: ['h1', '🎨', '🔘', '🔤'],
    buttonName: ['fonts', 'colours', 'buttons', 'inputs'],
  }),
}
</script>
