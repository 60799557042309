import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

// getters
export const IS_VISIBLE = 'snackbar/isVisible'
export const MESSAGE = 'snackbar/message'

//mutations
export const SHOW_MESSAGE = 'snackbar/showMessage'
export const SET_VISIBLE = 'snackbar/toggle'

@Module({
  name: 'snackbar',
  namespaced: true,
})
export default class Snackbar extends VuexModule {
  visible = false
  _message = ''

  get isVisible() {
    return this.visible
  }

  get message() {
    return this._message
  }

  @Mutation
  async showMessage(message: string) {
    this._message = message
    this.visible = true
  }

  @Mutation
  async toggle(flag: boolean) {
    this.visible = flag
  }
}
