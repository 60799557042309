import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/es5/util/colors'
import { Iconfont } from 'vuetify/types/services/icons'
import {
  ArrowDownIcon,
  BoxIcon,
  CalendarIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  DownloadIcon,
  EyeIcon,
  EyeOffIcon,
  HomeIcon,
  InfoIcon,
  MenuIcon,
  MinusIcon,
  PlusIcon,
  MoreVerticalIcon,
  SearchIcon,
  UploadIcon,
  XCircleIcon,
  XIcon,
} from 'vue-feather-icons'

import AreaIcon from '@/components/icons/area-icon.vue'
import AvatarIcon from '@/components/icons/avatar.vue'
import CheckboxOffIcon from '@/components/icons/checkbox-off.vue'
import CheckboxOnIcon from '@/components/icons/checkbox-on.vue'
import CheckInnerCircleIcon from '@/components/icons/check-circle.vue'
import CheckInnerCircleOutlinedIcon from '@/components/icons/check-circle-outlined.vue'
import CheckInnerCircleFilledIcon from '@/components/icons/check-circle-filled.vue'
import CircleFilledIcon from '@/components/icons/circle-filled.vue'
import CircleOutlinedIcon from '@/components/icons/circle-outlined.vue'
import CircleDisabledIcon from '@/components/icons/circle-disabled.vue'
import CrossCircleIcon from '@/components/icons/cross-circle.vue'
import LotPropsIcon from '@/components/icons/lot-props.vue'
import RadioOffIcon from '@/components/icons/radio-off.vue'
import RadioOnIcon from '@/components/icons/radio-on.vue'
import LineCircleIcon from '@/components/icons/line-circle.vue'

Vue.use(Vuetify)

export default new Vuetify({
  treeShake: true,
  customVariables: ['@/assets/scss/vuetifyVariables.scss'],
  theme: {
    dark: false,
    disable: false,
    default: false,
    options: {},
    themes: {
      light: {
        'grey-silver': '#C4C7CD',
        'grey-cultured': '#DFE1E4',
        'grey-light': '#FAFAFA',
        'grey-background': '#F5F5F5',
        'primary-space': '#222B45',
        'primary-independence': '#3D455C',
        'primary-coral': '#727C8E',
        primary: '#222B45',
        secondary: '#3D455C',
        accent: '#727C8E',
        info: '#96BDFF',
        'info-light': '#E6EEFA',
        warning: '#FFBA49',
        error: '#FB7181',
        'error-light': '#F9DFE2',
        success: '#08BE51',
        'success-light': '#C7EAD6',
        'accent-aquamarine': '#64D0AD',
        'accent-turquoise': '#65DACC',
        'accent-pine-light': '#378B85',
        'accent-pine': '#006E66',
      },
      dark: {
        primary: colors.blue.darken2,
        accent: colors.grey.darken3,
        secondary: colors.amber.darken3,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3,
      },
    },
  },
  icons: {
    // we don't need any icons, using custom feather icons only
    iconfont: '' as Iconfont,
    values: {
      area: {
        component: AreaIcon,
      },
      avatar: {
        component: AvatarIcon,
      },
      box: {
        component: BoxIcon,
      },
      calendar: {
        component: CalendarIcon,
        props: {
          size: '20',
        },
      },
      checkboxOn: {
        component: CheckboxOnIcon,
      },
      checkboxOff: {
        component: CheckboxOffIcon,
      },
      checkCircle: {
        component: CheckCircleIcon,
      },
      checkInnerCircle: {
        component: CheckInnerCircleIcon,
      },
      checkInnerCircleFilled: {
        component: CheckInnerCircleFilledIcon,
      },
      checkInnerCircleOutlined: {
        component: CheckInnerCircleOutlinedIcon,
      },
      chevronLeft: {
        component: ChevronLeftIcon,
      },
      circleDisabled: {
        component: CircleDisabledIcon,
      },
      circleFilled: {
        component: CircleFilledIcon,
      },
      circleOutlined: {
        component: CircleOutlinedIcon,
      },
      clear: {
        component: XIcon,
      },
      clock: {
        component: ClockIcon,
      },
      crossCircle: {
        component: CrossCircleIcon,
      },
      download: {
        component: DownloadIcon,
      },
      dropdown: {
        component: ChevronDownIcon,
      },
      error: {
        component: XCircleIcon,
      },
      expand: {
        component: ChevronDownIcon,
      },
      eye: {
        component: EyeIcon,
      },
      'eye-off': {
        component: EyeOffIcon,
      },
      home: {
        component: HomeIcon,
      },
      info: {
        component: InfoIcon,
      },
      kebab: {
        component: MoreVerticalIcon,
      },
      lotProps: {
        component: LotPropsIcon,
      },
      menu: {
        component: MenuIcon,
      },
      minus: {
        component: MinusIcon,
      },
      plus: {
        component: PlusIcon,
      },
      next: {
        component: ChevronRightIcon,
      },
      prev: {
        component: ChevronLeftIcon,
      },
      radioOff: {
        component: RadioOffIcon,
      },
      radioOn: {
        component: RadioOnIcon,
      },
      search: {
        component: SearchIcon,
      },
      sort: {
        component: ArrowDownIcon,
      },
      file: {
        component: UploadIcon,
      },
      lineCircle: {
        component: LineCircleIcon,
      },
    },
  },
})
