import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

export default function() {
  if (process.env.VUE_APP_SENTRY_DSN)
    Sentry.init({
      Vue: Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      environment: process.env.VUE_APP_SENTRY_ENV,
      tracingOptions: {
        trackComponents: true,
      },
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1,
    })
  else if (process.env.NODE_ENV === 'production')
    throw new Error('Sentry is required in production')
}
