import { INIT, FETCH_SESSION } from '@/store/auth'
import store from '@/modules/store'

export default async function init() {
  await store.dispatch(INIT, {
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_APP_CLIENT_ID,
  })
  await store.dispatch(FETCH_SESSION).catch(() => {
    // Do nothing, user may not have a session
  })
}
