/* tslint:disable */
/* eslint-disable */
/**
 * ReleaseMe
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'createdTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'modifiedTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'line1': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'line3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'suburb': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface AddressDTO
 */
export interface AddressDTO {
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    'line1': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    'line3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    'suburb': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface AllocationDTO
 */
export interface AllocationDTO {
    /**
     * 
     * @type {string}
     * @memberof AllocationDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationDTO
     */
    'applicationId': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationDTO
     */
    'lotId': string;
    /**
     * 
     * @type {number}
     * @memberof AllocationDTO
     */
    'order': number;
    /**
     * 
     * @type {AllocationStatus}
     * @memberof AllocationDTO
     */
    'status': AllocationStatus;
    /**
     * 
     * @type {string}
     * @memberof AllocationDTO
     */
    'acceptedDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationDTO
     */
    'rejectedDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationDTO
     */
    'completeDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationDTO
     */
    'incompleteDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationDTO
     */
    'depositDescription': string;
    /**
     * 
     * @type {boolean}
     * @memberof AllocationDTO
     */
    'particularsComplete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AllocationDTO
     */
    'signingTimeComplete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AllocationDTO
     */
    'depositComplete': boolean;
    /**
     * 
     * @type {string}
     * @memberof AllocationDTO
     */
    'acceptanceEndDateTime'?: string | null;
    /**
     * 
     * @type {ReleaseSigningTimeDTO}
     * @memberof AllocationDTO
     */
    'signingTime'?: ReleaseSigningTimeDTO;
    /**
     * 
     * @type {AllocationType}
     * @memberof AllocationDTO
     */
    'allocationType'?: AllocationType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AllocationStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum AllocationType {
    Preference = 'Preference',
    OnDemand = 'OnDemand'
}

/**
 * 
 * @export
 * @interface ApplicationDTO
 */
export interface ApplicationDTO {
    /**
     * 
     * @type {string}
     * @memberof ApplicationDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDTO
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDTO
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDTO
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDTO
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDTO
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDTO
     */
    'releaseId': string;
    /**
     * 
     * @type {Array<PreferenceDTO>}
     * @memberof ApplicationDTO
     */
    'preferences': Array<PreferenceDTO>;
    /**
     * 
     * @type {ApplicationStatus}
     * @memberof ApplicationDTO
     */
    'status'?: ApplicationStatus;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDTO
     */
    'submittedDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDTO
     */
    'cancelledDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDTO
     */
    'allocatedDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDTO
     */
    'unallocatedDateTime'?: string | null;
    /**
     * 
     * @type {ReleaseDTO}
     * @memberof ApplicationDTO
     */
    'release'?: ReleaseDTO;
    /**
     * 
     * @type {AllocationDTO}
     * @memberof ApplicationDTO
     */
    'allocation'?: AllocationDTO;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ApplicationStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

/**
 * 
 * @export
 * @interface ContactDTO
 */
export interface ContactDTO {
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContactDTO
     */
    'isAustralianResident': boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    'identificationType': string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    'identificationAuthority': string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    'identificationNumber': string;
}
/**
 * 
 * @export
 * @interface FileDTO
 */
export interface FileDTO {
    /**
     * 
     * @type {string}
     * @memberof FileDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FileDTO
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof FileDTO
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface LotAvailabilityDTO
 */
export interface LotAvailabilityDTO {
    /**
     * 
     * @type {boolean}
     * @memberof LotAvailabilityDTO
     */
    'isRemovedFromSale': boolean;
}
/**
 * 
 * @export
 * @interface LotDTO
 */
export interface LotDTO {
    /**
     * 
     * @type {string}
     * @memberof LotDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LotDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LotDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LotDTO
     */
    'length'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LotDTO
     */
    'width'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LotDTO
     */
    'size'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LotDTO
     */
    'landArea'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LotDTO
     */
    'buildingArea'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LotDTO
     */
    'bedrooms'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LotDTO
     */
    'bathrooms'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LotDTO
     */
    'carSpaces'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LotDTO
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LotDTO
     */
    'isRemovedFromSale'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum LotStatus {
    Available = 'Available',
    OnHold = 'OnHold',
    Secured = 'Secured',
    Unavailable = 'Unavailable'
}

/**
 * 
 * @export
 * @interface ParticularAnswerDTO
 */
export interface ParticularAnswerDTO {
    /**
     * 
     * @type {string}
     * @memberof ParticularAnswerDTO
     */
    'allocationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticularAnswerDTO
     */
    'particularQuestionId': string;
    /**
     * 
     * @type {string}
     * @memberof ParticularAnswerDTO
     */
    'answer': string;
}
/**
 * 
 * @export
 * @interface ParticularQuestionDTO
 */
export interface ParticularQuestionDTO {
    /**
     * 
     * @type {string}
     * @memberof ParticularQuestionDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticularQuestionDTO
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ParticularQuestionDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ParticularQuestionDTO
     */
    'isRequired': boolean;
}
/**
 * 
 * @export
 * @interface ParticularsDTO
 */
export interface ParticularsDTO {
    /**
     * 
     * @type {string}
     * @memberof ParticularsDTO
     */
    'allocationId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticularsDTO
     */
    'isPurchaserCompanyOrTrust': boolean;
    /**
     * 
     * @type {string}
     * @memberof ParticularsDTO
     */
    'entityName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticularsDTO
     */
    'entityAbn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticularsDTO
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof ParticularsDTO
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof ParticularsDTO
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof ParticularsDTO
     */
    'phoneNumber': string;
    /**
     * 
     * @type {Array<ContactDTO>}
     * @memberof ParticularsDTO
     */
    'otherContacts'?: Array<ContactDTO> | null;
    /**
     * 
     * @type {AddressDTO}
     * @memberof ParticularsDTO
     */
    'address': AddressDTO;
    /**
     * 
     * @type {boolean}
     * @memberof ParticularsDTO
     */
    'isSubjectToFinance': boolean;
    /**
     * 
     * @type {string}
     * @memberof ParticularsDTO
     */
    'lenderName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ParticularsDTO
     */
    'loanAmount'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ParticularsDTO
     */
    'isAustralianResident': boolean;
    /**
     * 
     * @type {string}
     * @memberof ParticularsDTO
     */
    'identificationType': string;
    /**
     * 
     * @type {string}
     * @memberof ParticularsDTO
     */
    'identificationAuthority': string;
    /**
     * 
     * @type {string}
     * @memberof ParticularsDTO
     */
    'identificationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ParticularsDTO
     */
    'conveyancerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticularsDTO
     */
    'conveyancerEmailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticularsDTO
     */
    'conveyancerPhoneNumber'?: string | null;
    /**
     * 
     * @type {AddressDTO}
     * @memberof ParticularsDTO
     */
    'conveyancerAddress'?: AddressDTO;
}
/**
 * 
 * @export
 * @interface PaymentSessionDTO
 */
export interface PaymentSessionDTO {
    /**
     * 
     * @type {number}
     * @memberof PaymentSessionDTO
     */
    'pricePerLot'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentSessionDTO
     */
    'numberOfLots'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentSessionDTO
     */
    'totalPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentSessionDTO
     */
    'stripeSessionId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentSessionDTO
     */
    'invoiceOnly'?: boolean;
}
/**
 * 
 * @export
 * @interface PostLotsDTO
 */
export interface PostLotsDTO {
    /**
     * 
     * @type {Array<LotDTO>}
     * @memberof PostLotsDTO
     */
    'lots': Array<LotDTO>;
}
/**
 * 
 * @export
 * @interface PostSigningTimesDTO
 */
export interface PostSigningTimesDTO {
    /**
     * 
     * @type {Array<ReleaseSigningTimeDTO>}
     * @memberof PostSigningTimesDTO
     */
    'signingTimes': Array<ReleaseSigningTimeDTO>;
}
/**
 * 
 * @export
 * @interface PreferenceDTO
 */
export interface PreferenceDTO {
    /**
     * 
     * @type {string}
     * @memberof PreferenceDTO
     */
    'lotId': string;
    /**
     * 
     * @type {number}
     * @memberof PreferenceDTO
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PropertyType {
    Lot = 'Lot',
    Apartment = 'Apartment',
    Townhouse = 'Townhouse'
}

/**
 * 
 * @export
 * @interface PublishResponseDTO
 */
export interface PublishResponseDTO {
    /**
     * 
     * @type {ReleaseDTO}
     * @memberof PublishResponseDTO
     */
    'release'?: ReleaseDTO;
    /**
     * 
     * @type {PaymentSessionDTO}
     * @memberof PublishResponseDTO
     */
    'paymentSession'?: PaymentSessionDTO;
}
/**
 * 
 * @export
 * @interface PutLotsDTO
 */
export interface PutLotsDTO {
    /**
     * 
     * @type {string}
     * @memberof PutLotsDTO
     */
    'fileName': string;
    /**
     * 
     * @type {Array<LotDTO>}
     * @memberof PutLotsDTO
     */
    'lots': Array<LotDTO>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum QueueProcessorMessageNotifyType {
    Submission = 'SUBMISSION',
    Allocation = 'ALLOCATION',
    Rejection = 'REJECTION',
    Completion = 'COMPLETION',
    TimedOut = 'TIMED_OUT',
    RemainingLots = 'REMAINING_LOTS',
    SigningTime = 'SIGNING_TIME',
    TaxInvoice = 'TAX_INVOICE',
    Reminder = 'REMINDER',
    MissedOut = 'MISSED_OUT',
    PurchaseStarted = 'PURCHASE_STARTED',
    PurchaseExpired = 'PURCHASE_EXPIRED'
}

/**
 * 
 * @export
 * @interface ReleaseDTO
 */
export interface ReleaseDTO {
    /**
     * 
     * @type {string}
     * @memberof ReleaseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseDTO
     */
    'code': string;
    /**
     * 
     * @type {ReleaseStatus}
     * @memberof ReleaseDTO
     */
    'status': ReleaseStatus;
    /**
     * 
     * @type {string}
     * @memberof ReleaseDTO
     */
    'lotsImportFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReleaseDTO
     */
    'lotsImportDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReleaseDTO
     */
    'cancelledDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReleaseDTO
     */
    'publishedDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReleaseDTO
     */
    'allocatingDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReleaseDTO
     */
    'completeDateTime'?: string | null;
    /**
     * 
     * @type {ReleaseDetailDTO}
     * @memberof ReleaseDTO
     */
    'details'?: ReleaseDetailDTO;
    /**
     * 
     * @type {number}
     * @memberof ReleaseDTO
     */
    'numberOfApplications'?: number;
    /**
     * 
     * @type {Array<FileDTO>}
     * @memberof ReleaseDTO
     */
    'downloads'?: Array<FileDTO> | null;
    /**
     * 
     * @type {Array<ParticularQuestionDTO>}
     * @memberof ReleaseDTO
     */
    'particularQuestions': Array<ParticularQuestionDTO>;
    /**
     * 
     * @type {ReleaseSigningDetailDTO}
     * @memberof ReleaseDTO
     */
    'signingDetails'?: ReleaseSigningDetailDTO;
    /**
     * 
     * @type {Array<ReleaseSigningTimeDTO>}
     * @memberof ReleaseDTO
     */
    'signingTimes': Array<ReleaseSigningTimeDTO>;
    /**
     * 
     * @type {Array<LotDTO>}
     * @memberof ReleaseDTO
     */
    'lots': Array<LotDTO>;
    /**
     * 
     * @type {Array<FileDTO>}
     * @memberof ReleaseDTO
     */
    'documents': Array<FileDTO>;
    /**
     * 
     * @type {FileDTO}
     * @memberof ReleaseDTO
     */
    'projectImage'?: FileDTO;
    /**
     * 
     * @type {FileDTO}
     * @memberof ReleaseDTO
     */
    'developerImage'?: FileDTO;
    /**
     * 
     * @type {{ [key: string]: LotStatus; }}
     * @memberof ReleaseDTO
     */
    'lotStatusMap'?: { [key: string]: LotStatus; } | null;
}
/**
 * 
 * @export
 * @interface ReleaseDetailDTO
 */
export interface ReleaseDetailDTO {
    /**
     * 
     * @type {string}
     * @memberof ReleaseDetailDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseDetailDTO
     */
    'website': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseDetailDTO
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseDetailDTO
     */
    'developerName': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseDetailDTO
     */
    'applicationsCloseDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseDetailDTO
     */
    'allocationDate': string;
    /**
     * 
     * @type {PropertyType}
     * @memberof ReleaseDetailDTO
     */
    'propertyType': PropertyType;
    /**
     * 
     * @type {boolean}
     * @memberof ReleaseDetailDTO
     */
    'onDemandPurchasingEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReleaseDetailDTO
     */
    'skipAllocationsPhase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReleaseDetailDTO
     */
    'useProjectImageEmails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReleaseDetailDTO
     */
    'useDeveloperImageEmails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReleaseDetailDTO
     */
    'useDeveloperImageWeb'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReleaseDetailDTO
     */
    'skipRemainingLotsEmail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReleaseDetailDTO
     */
    'releaseIanaTimezone'?: string | null;
}
/**
 * 
 * @export
 * @interface ReleaseSigningDetailDTO
 */
export interface ReleaseSigningDetailDTO {
    /**
     * 
     * @type {string}
     * @memberof ReleaseSigningDetailDTO
     */
    'depositAccountName': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseSigningDetailDTO
     */
    'depositAccountBsb': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseSigningDetailDTO
     */
    'depositAccountNumber': string;
    /**
     * 
     * @type {number}
     * @memberof ReleaseSigningDetailDTO
     */
    'depositAmount': number;
    /**
     * 
     * @type {string}
     * @memberof ReleaseSigningDetailDTO
     */
    'legalPractitionerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseSigningDetailDTO
     */
    'salesConsultantName': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseSigningDetailDTO
     */
    'salesConsultantPhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseSigningDetailDTO
     */
    'salesConsultantEmail': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseSigningDetailDTO
     */
    'signingLocation': string;
    /**
     * 
     * @type {number}
     * @memberof ReleaseSigningDetailDTO
     */
    'financeTimeframe': number;
    /**
     * 
     * @type {string}
     * @memberof ReleaseSigningDetailDTO
     */
    'signingInstructions'?: string | null;
}
/**
 * 
 * @export
 * @interface ReleaseSigningTimeDTO
 */
export interface ReleaseSigningTimeDTO {
    /**
     * 
     * @type {string}
     * @memberof ReleaseSigningTimeDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseSigningTimeDTO
     */
    'dateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseSigningTimeDTO
     */
    'dateTo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReleaseSigningTimeDTO
     */
    'isAssigned'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ReleaseStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6
}

/**
 * 
 * @export
 * @interface Seller
 */
export interface Seller {
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'createdTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'modifiedTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'realEstateLicenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'companyAbn': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'companyAddressId': string;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    'marketingOptIn': boolean;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'stripeCustomerId': string;
    /**
     * 
     * @type {Address}
     * @memberof Seller
     */
    'companyAddress'?: Address;
}
/**
 * 
 * @export
 * @interface SellerDTO
 */
export interface SellerDTO {
    /**
     * 
     * @type {string}
     * @memberof SellerDTO
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof SellerDTO
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof SellerDTO
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof SellerDTO
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SellerDTO
     */
    'realEstateLicenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SellerDTO
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof SellerDTO
     */
    'companyAbn': string;
    /**
     * 
     * @type {AddressDTO}
     * @memberof SellerDTO
     */
    'companyAddress': AddressDTO;
    /**
     * 
     * @type {boolean}
     * @memberof SellerDTO
     */
    'marketingOptIn': boolean;
}
/**
 * 
 * @export
 * @interface SellerRegisterDTO
 */
export interface SellerRegisterDTO {
    /**
     * 
     * @type {string}
     * @memberof SellerRegisterDTO
     */
    'password'?: string | null;
    /**
     * 
     * @type {SellerDTO}
     * @memberof SellerRegisterDTO
     */
    'seller'?: SellerDTO;
}
/**
 * 
 * @export
 * @interface StartLotsUploadDTO
 */
export interface StartLotsUploadDTO {
    /**
     * 
     * @type {string}
     * @memberof StartLotsUploadDTO
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface StatisticAllocationDTO
 */
export interface StatisticAllocationDTO {
    /**
     * 
     * @type {string}
     * @memberof StatisticAllocationDTO
     */
    'lotName'?: string | null;
    /**
     * 
     * @type {AllocationStatus}
     * @memberof StatisticAllocationDTO
     */
    'status'?: AllocationStatus;
    /**
     * 
     * @type {AllocationType}
     * @memberof StatisticAllocationDTO
     */
    'allocationType'?: AllocationType;
    /**
     * 
     * @type {string}
     * @memberof StatisticAllocationDTO
     */
    'givenName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatisticAllocationDTO
     */
    'familyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatisticAllocationDTO
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatisticAllocationDTO
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticAllocationDTO
     */
    'particularsComplete'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticAllocationDTO
     */
    'signingTimeComplete'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticAllocationDTO
     */
    'depositComplete'?: boolean;
    /**
     * 
     * @type {Array<FileDTO>}
     * @memberof StatisticAllocationDTO
     */
    'downloads'?: Array<FileDTO> | null;
}
/**
 * 
 * @export
 * @interface StatisticApplicationDTO
 */
export interface StatisticApplicationDTO {
    /**
     * 
     * @type {string}
     * @memberof StatisticApplicationDTO
     */
    'lotName'?: string | null;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof StatisticApplicationDTO
     */
    'preferences'?: { [key: string]: number; } | null;
}
/**
 * 
 * @export
 * @interface StatisticsDTO
 */
export interface StatisticsDTO {
    /**
     * 
     * @type {Array<StatisticApplicationDTO>}
     * @memberof StatisticsDTO
     */
    'applications'?: Array<StatisticApplicationDTO> | null;
    /**
     * 
     * @type {Array<StatisticAllocationDTO>}
     * @memberof StatisticsDTO
     */
    'allocations'?: Array<StatisticAllocationDTO> | null;
}

/**
 * ApplicationsApi - axios parameter creator
 * @export
 */
export const ApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accept an application allocation
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdAcceptPost: async (applicationId: string, key?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('v1ApplicationsApplicationIdAcceptPost', 'applicationId', applicationId)
            const localVarPath = `/v1/Applications/{applicationId}/accept`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel an application
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdCancelPost: async (applicationId: string, key?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('v1ApplicationsApplicationIdCancelPost', 'applicationId', applicationId)
            const localVarPath = `/v1/Applications/{applicationId}/cancel`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm deposit
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {FileDTO} [fileDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdDepositPost: async (applicationId: string, key?: string, fileDTO?: FileDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('v1ApplicationsApplicationIdDepositPost', 'applicationId', applicationId)
            const localVarPath = `/v1/Applications/{applicationId}/deposit`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Force send an email
         * @param {string} applicationId 
         * @param {QueueProcessorMessageNotifyType} emailType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdForceEmailEmailTypePost: async (applicationId: string, emailType: QueueProcessorMessageNotifyType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('v1ApplicationsApplicationIdForceEmailEmailTypePost', 'applicationId', applicationId)
            // verify required parameter 'emailType' is not null or undefined
            assertParamExists('v1ApplicationsApplicationIdForceEmailEmailTypePost', 'emailType', emailType)
            const localVarPath = `/v1/Applications/{applicationId}/forceEmail/{emailType}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)))
                .replace(`{${"emailType"}}`, encodeURIComponent(String(emailType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an application
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdGet: async (applicationId: string, key?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('v1ApplicationsApplicationIdGet', 'applicationId', applicationId)
            const localVarPath = `/v1/Applications/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit custom particulars for an application allocation
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {Array<ParticularAnswerDTO>} [particularAnswerDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdParticularAnswersPost: async (applicationId: string, key?: string, particularAnswerDTO?: Array<ParticularAnswerDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('v1ApplicationsApplicationIdParticularAnswersPost', 'applicationId', applicationId)
            const localVarPath = `/v1/Applications/{applicationId}/particularAnswers`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(particularAnswerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit particulars for an application allocation
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {ParticularsDTO} [particularsDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdParticularsPost: async (applicationId: string, key?: string, particularsDTO?: ParticularsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('v1ApplicationsApplicationIdParticularsPost', 'applicationId', applicationId)
            const localVarPath = `/v1/Applications/{applicationId}/particulars`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(particularsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reject an application allocation
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdRejectPost: async (applicationId: string, key?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('v1ApplicationsApplicationIdRejectPost', 'applicationId', applicationId)
            const localVarPath = `/v1/Applications/{applicationId}/reject`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit signing time for an application allocation
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {ReleaseSigningTimeDTO} [releaseSigningTimeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdSigningTimePost: async (applicationId: string, key?: string, releaseSigningTimeDTO?: ReleaseSigningTimeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('v1ApplicationsApplicationIdSigningTimePost', 'applicationId', applicationId)
            const localVarPath = `/v1/Applications/{applicationId}/signingTime`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(releaseSigningTimeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Application.Id and Application.Key isn\'t required.
         * @summary Create an application.
         * @param {ApplicationDTO} [applicationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsPost: async (applicationDTO?: ApplicationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationsApi - functional programming interface
 * @export
 */
export const ApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Accept an application allocation
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ApplicationsApplicationIdAcceptPost(applicationId: string, key?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ApplicationsApplicationIdAcceptPost(applicationId, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel an application
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ApplicationsApplicationIdCancelPost(applicationId: string, key?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ApplicationsApplicationIdCancelPost(applicationId, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirm deposit
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {FileDTO} [fileDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ApplicationsApplicationIdDepositPost(applicationId: string, key?: string, fileDTO?: FileDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ApplicationsApplicationIdDepositPost(applicationId, key, fileDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Force send an email
         * @param {string} applicationId 
         * @param {QueueProcessorMessageNotifyType} emailType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ApplicationsApplicationIdForceEmailEmailTypePost(applicationId: string, emailType: QueueProcessorMessageNotifyType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ApplicationsApplicationIdForceEmailEmailTypePost(applicationId, emailType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an application
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ApplicationsApplicationIdGet(applicationId: string, key?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ApplicationsApplicationIdGet(applicationId, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submit custom particulars for an application allocation
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {Array<ParticularAnswerDTO>} [particularAnswerDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ApplicationsApplicationIdParticularAnswersPost(applicationId: string, key?: string, particularAnswerDTO?: Array<ParticularAnswerDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ApplicationsApplicationIdParticularAnswersPost(applicationId, key, particularAnswerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submit particulars for an application allocation
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {ParticularsDTO} [particularsDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ApplicationsApplicationIdParticularsPost(applicationId: string, key?: string, particularsDTO?: ParticularsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ApplicationsApplicationIdParticularsPost(applicationId, key, particularsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reject an application allocation
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ApplicationsApplicationIdRejectPost(applicationId: string, key?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ApplicationsApplicationIdRejectPost(applicationId, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submit signing time for an application allocation
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {ReleaseSigningTimeDTO} [releaseSigningTimeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ApplicationsApplicationIdSigningTimePost(applicationId: string, key?: string, releaseSigningTimeDTO?: ReleaseSigningTimeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ApplicationsApplicationIdSigningTimePost(applicationId, key, releaseSigningTimeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Application.Id and Application.Key isn\'t required.
         * @summary Create an application.
         * @param {ApplicationDTO} [applicationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ApplicationsPost(applicationDTO?: ApplicationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ApplicationsPost(applicationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationsApi - factory interface
 * @export
 */
export const ApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Accept an application allocation
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdAcceptPost(applicationId: string, key?: string, options?: any): AxiosPromise<ApplicationDTO> {
            return localVarFp.v1ApplicationsApplicationIdAcceptPost(applicationId, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel an application
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdCancelPost(applicationId: string, key?: string, options?: any): AxiosPromise<ApplicationDTO> {
            return localVarFp.v1ApplicationsApplicationIdCancelPost(applicationId, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm deposit
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {FileDTO} [fileDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdDepositPost(applicationId: string, key?: string, fileDTO?: FileDTO, options?: any): AxiosPromise<FileDTO> {
            return localVarFp.v1ApplicationsApplicationIdDepositPost(applicationId, key, fileDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Force send an email
         * @param {string} applicationId 
         * @param {QueueProcessorMessageNotifyType} emailType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdForceEmailEmailTypePost(applicationId: string, emailType: QueueProcessorMessageNotifyType, options?: any): AxiosPromise<void> {
            return localVarFp.v1ApplicationsApplicationIdForceEmailEmailTypePost(applicationId, emailType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an application
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdGet(applicationId: string, key?: string, options?: any): AxiosPromise<ApplicationDTO> {
            return localVarFp.v1ApplicationsApplicationIdGet(applicationId, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit custom particulars for an application allocation
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {Array<ParticularAnswerDTO>} [particularAnswerDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdParticularAnswersPost(applicationId: string, key?: string, particularAnswerDTO?: Array<ParticularAnswerDTO>, options?: any): AxiosPromise<ApplicationDTO> {
            return localVarFp.v1ApplicationsApplicationIdParticularAnswersPost(applicationId, key, particularAnswerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit particulars for an application allocation
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {ParticularsDTO} [particularsDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdParticularsPost(applicationId: string, key?: string, particularsDTO?: ParticularsDTO, options?: any): AxiosPromise<ApplicationDTO> {
            return localVarFp.v1ApplicationsApplicationIdParticularsPost(applicationId, key, particularsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reject an application allocation
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdRejectPost(applicationId: string, key?: string, options?: any): AxiosPromise<ApplicationDTO> {
            return localVarFp.v1ApplicationsApplicationIdRejectPost(applicationId, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit signing time for an application allocation
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {ReleaseSigningTimeDTO} [releaseSigningTimeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsApplicationIdSigningTimePost(applicationId: string, key?: string, releaseSigningTimeDTO?: ReleaseSigningTimeDTO, options?: any): AxiosPromise<ApplicationDTO> {
            return localVarFp.v1ApplicationsApplicationIdSigningTimePost(applicationId, key, releaseSigningTimeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Application.Id and Application.Key isn\'t required.
         * @summary Create an application.
         * @param {ApplicationDTO} [applicationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApplicationsPost(applicationDTO?: ApplicationDTO, options?: any): AxiosPromise<ApplicationDTO> {
            return localVarFp.v1ApplicationsPost(applicationDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationsApi - object-oriented interface
 * @export
 * @class ApplicationsApi
 * @extends {BaseAPI}
 */
export class ApplicationsApi extends BaseAPI {
    /**
     * 
     * @summary Accept an application allocation
     * @param {string} applicationId 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public v1ApplicationsApplicationIdAcceptPost(applicationId: string, key?: string, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).v1ApplicationsApplicationIdAcceptPost(applicationId, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel an application
     * @param {string} applicationId 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public v1ApplicationsApplicationIdCancelPost(applicationId: string, key?: string, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).v1ApplicationsApplicationIdCancelPost(applicationId, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm deposit
     * @param {string} applicationId 
     * @param {string} [key] 
     * @param {FileDTO} [fileDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public v1ApplicationsApplicationIdDepositPost(applicationId: string, key?: string, fileDTO?: FileDTO, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).v1ApplicationsApplicationIdDepositPost(applicationId, key, fileDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Force send an email
     * @param {string} applicationId 
     * @param {QueueProcessorMessageNotifyType} emailType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public v1ApplicationsApplicationIdForceEmailEmailTypePost(applicationId: string, emailType: QueueProcessorMessageNotifyType, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).v1ApplicationsApplicationIdForceEmailEmailTypePost(applicationId, emailType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an application
     * @param {string} applicationId 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public v1ApplicationsApplicationIdGet(applicationId: string, key?: string, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).v1ApplicationsApplicationIdGet(applicationId, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit custom particulars for an application allocation
     * @param {string} applicationId 
     * @param {string} [key] 
     * @param {Array<ParticularAnswerDTO>} [particularAnswerDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public v1ApplicationsApplicationIdParticularAnswersPost(applicationId: string, key?: string, particularAnswerDTO?: Array<ParticularAnswerDTO>, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).v1ApplicationsApplicationIdParticularAnswersPost(applicationId, key, particularAnswerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit particulars for an application allocation
     * @param {string} applicationId 
     * @param {string} [key] 
     * @param {ParticularsDTO} [particularsDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public v1ApplicationsApplicationIdParticularsPost(applicationId: string, key?: string, particularsDTO?: ParticularsDTO, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).v1ApplicationsApplicationIdParticularsPost(applicationId, key, particularsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reject an application allocation
     * @param {string} applicationId 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public v1ApplicationsApplicationIdRejectPost(applicationId: string, key?: string, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).v1ApplicationsApplicationIdRejectPost(applicationId, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit signing time for an application allocation
     * @param {string} applicationId 
     * @param {string} [key] 
     * @param {ReleaseSigningTimeDTO} [releaseSigningTimeDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public v1ApplicationsApplicationIdSigningTimePost(applicationId: string, key?: string, releaseSigningTimeDTO?: ReleaseSigningTimeDTO, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).v1ApplicationsApplicationIdSigningTimePost(applicationId, key, releaseSigningTimeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Application.Id and Application.Key isn\'t required.
     * @summary Create an application.
     * @param {ApplicationDTO} [applicationDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public v1ApplicationsPost(applicationDTO?: ApplicationDTO, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).v1ApplicationsPost(applicationDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthCheckApi - axios parameter creator
 * @export
 */
export const HealthCheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1HealthCheckGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/HealthCheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthCheckApi - functional programming interface
 * @export
 */
export const HealthCheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthCheckApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1HealthCheckGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1HealthCheckGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthCheckApi - factory interface
 * @export
 */
export const HealthCheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthCheckApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1HealthCheckGet(options?: any): AxiosPromise<void> {
            return localVarFp.v1HealthCheckGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthCheckApi - object-oriented interface
 * @export
 * @class HealthCheckApi
 * @extends {BaseAPI}
 */
export class HealthCheckApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCheckApi
     */
    public v1HealthCheckGet(options?: AxiosRequestConfig) {
        return HealthCheckApiFp(this.configuration).v1HealthCheckGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PurchasesApi - axios parameter creator
 * @export
 */
export const PurchasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Purchase a property directly for a \"OnDemandPurchasing\" release
         * @param {ApplicationDTO} [applicationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PurchasesPost: async (applicationDTO?: ApplicationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Purchases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchasesApi - functional programming interface
 * @export
 */
export const PurchasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchasesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Purchase a property directly for a \"OnDemandPurchasing\" release
         * @param {ApplicationDTO} [applicationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PurchasesPost(applicationDTO?: ApplicationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PurchasesPost(applicationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PurchasesApi - factory interface
 * @export
 */
export const PurchasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchasesApiFp(configuration)
    return {
        /**
         * 
         * @summary Purchase a property directly for a \"OnDemandPurchasing\" release
         * @param {ApplicationDTO} [applicationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PurchasesPost(applicationDTO?: ApplicationDTO, options?: any): AxiosPromise<ApplicationDTO> {
            return localVarFp.v1PurchasesPost(applicationDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchasesApi - object-oriented interface
 * @export
 * @class PurchasesApi
 * @extends {BaseAPI}
 */
export class PurchasesApi extends BaseAPI {
    /**
     * 
     * @summary Purchase a property directly for a \"OnDemandPurchasing\" release
     * @param {ApplicationDTO} [applicationDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasesApi
     */
    public v1PurchasesPost(applicationDTO?: ApplicationDTO, options?: AxiosRequestConfig) {
        return PurchasesApiFp(this.configuration).v1PurchasesPost(applicationDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReleasesApi - axios parameter creator
 * @export
 */
export const ReleasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all releases or a release based on a code.
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesGet: async (code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Releases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a release.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Releases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdCancelPost: async (releaseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdCancelPost', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/cancel`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete a release, limited to On Demand Purchasing releases
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdCompletePost: async (releaseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdCompletePost', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/complete`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a release (only available to draft releases)
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdDelete: async (releaseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdDelete', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add release details.
         * @param {string} releaseId 
         * @param {ReleaseDetailDTO} [releaseDetailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdDetailsPut: async (releaseId: string, releaseDetailDTO?: ReleaseDetailDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdDetailsPut', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/details`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(releaseDetailDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a developer image to a release
         * @param {string} releaseId 
         * @param {FileDTO} [fileDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdDeveloperImagePut: async (releaseId: string, fileDTO?: FileDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdDeveloperImagePut', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/developerImage`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a file from a release
         * @param {string} releaseId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdDocumentsFileIdDelete: async (releaseId: string, fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdDocumentsFileIdDelete', 'releaseId', releaseId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdDocumentsFileIdDelete', 'fileId', fileId)
            const localVarPath = `/v1/Releases/{releaseId}/documents/{fileId}`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a document to a release
         * @param {string} releaseId 
         * @param {FileDTO} [fileDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdDocumentsPost: async (releaseId: string, fileDTO?: FileDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdDocumentsPost', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/documents`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Force allocate a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdForceAllocatePost: async (releaseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdForceAllocatePost', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/forceAllocate`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Force complete a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdForceCompletePost: async (releaseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdForceCompletePost', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/forceComplete`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Force publish a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdForcePublishPost: async (releaseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdForcePublishPost', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/forcePublish`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Force reminder on a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdForceReminderPost: async (releaseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdForceReminderPost', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/forceReminder`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Release based on an ID.
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdGet: async (releaseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdGet', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Raise an invoice item (instead of taking immediate payment) and then change the release status to published.
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdInvoicePost: async (releaseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdInvoicePost', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/invoice`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change lot availability
         * @param {string} releaseId 
         * @param {string} lotId 
         * @param {LotAvailabilityDTO} [lotAvailabilityDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdLotsLotIdPost: async (releaseId: string, lotId: string, lotAvailabilityDTO?: LotAvailabilityDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdLotsLotIdPost', 'releaseId', releaseId)
            // verify required parameter 'lotId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdLotsLotIdPost', 'lotId', lotId)
            const localVarPath = `/v1/Releases/{releaseId}/lots/{lotId}`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)))
                .replace(`{${"lotId"}}`, encodeURIComponent(String(lotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lotAvailabilityDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add lots for a release
         * @param {string} releaseId 
         * @param {PostLotsDTO} [postLotsDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdLotsPost: async (releaseId: string, postLotsDTO?: PostLotsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdLotsPost', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/lots`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postLotsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set lots for a release
         * @param {string} releaseId 
         * @param {PutLotsDTO} [putLotsDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdLotsPut: async (releaseId: string, putLotsDTO?: PutLotsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdLotsPut', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/lots`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putLotsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start upload of lots
         * @param {string} releaseId 
         * @param {StartLotsUploadDTO} [startLotsUploadDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdLotsStartPost: async (releaseId: string, startLotsUploadDTO?: StartLotsUploadDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdLotsStartPost', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/lots/start`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startLotsUploadDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add release particular questions.
         * @param {string} releaseId 
         * @param {Array<ParticularQuestionDTO>} [particularQuestionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdParticularQuestionsPut: async (releaseId: string, particularQuestionDTO?: Array<ParticularQuestionDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdParticularQuestionsPut', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/particularQuestions`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(particularQuestionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a project image to a release
         * @param {string} releaseId 
         * @param {FileDTO} [fileDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdProjectImagePut: async (releaseId: string, fileDTO?: FileDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdProjectImagePut', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/projectImage`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publish a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdPublishPost: async (releaseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdPublishPost', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/publish`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add release signing details.
         * @param {string} releaseId 
         * @param {ReleaseSigningDetailDTO} [releaseSigningDetailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdSigningDetailsPut: async (releaseId: string, releaseSigningDetailDTO?: ReleaseSigningDetailDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdSigningDetailsPut', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/signingDetails`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(releaseSigningDetailDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get release signing times
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdSigningTimesGet: async (releaseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdSigningTimesGet', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/signingTimes`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add signing times for a release
         * @param {string} releaseId 
         * @param {PostSigningTimesDTO} [postSigningTimesDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdSigningTimesPost: async (releaseId: string, postSigningTimesDTO?: PostSigningTimesDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdSigningTimesPost', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/signingTimes`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSigningTimesDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put release signing times.
         * @param {string} releaseId 
         * @param {Array<ReleaseSigningTimeDTO>} [releaseSigningTimeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdSigningTimesPut: async (releaseId: string, releaseSigningTimeDTO?: Array<ReleaseSigningTimeDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdSigningTimesPut', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/signingTimes`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(releaseSigningTimeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start upload of release signing times.
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdSigningTimesStartPost: async (releaseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdSigningTimesStartPost', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/signingTimes/start`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the release statistics
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdStatisticsGet: async (releaseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1ReleasesReleaseIdStatisticsGet', 'releaseId', releaseId)
            const localVarPath = `/v1/Releases/{releaseId}/statistics`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReleasesApi - functional programming interface
 * @export
 */
export const ReleasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReleasesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all releases or a release based on a code.
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesGet(code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesGet(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a release.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdCancelPost(releaseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdCancelPost(releaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete a release, limited to On Demand Purchasing releases
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdCompletePost(releaseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdCompletePost(releaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a release (only available to draft releases)
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdDelete(releaseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdDelete(releaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add release details.
         * @param {string} releaseId 
         * @param {ReleaseDetailDTO} [releaseDetailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdDetailsPut(releaseId: string, releaseDetailDTO?: ReleaseDetailDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleaseDetailDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdDetailsPut(releaseId, releaseDetailDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a developer image to a release
         * @param {string} releaseId 
         * @param {FileDTO} [fileDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdDeveloperImagePut(releaseId: string, fileDTO?: FileDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdDeveloperImagePut(releaseId, fileDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a file from a release
         * @param {string} releaseId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdDocumentsFileIdDelete(releaseId: string, fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdDocumentsFileIdDelete(releaseId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a document to a release
         * @param {string} releaseId 
         * @param {FileDTO} [fileDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdDocumentsPost(releaseId: string, fileDTO?: FileDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdDocumentsPost(releaseId, fileDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Force allocate a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdForceAllocatePost(releaseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdForceAllocatePost(releaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Force complete a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdForceCompletePost(releaseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdForceCompletePost(releaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Force publish a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdForcePublishPost(releaseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdForcePublishPost(releaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Force reminder on a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdForceReminderPost(releaseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdForceReminderPost(releaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a Release based on an ID.
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdGet(releaseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdGet(releaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Raise an invoice item (instead of taking immediate payment) and then change the release status to published.
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdInvoicePost(releaseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdInvoicePost(releaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change lot availability
         * @param {string} releaseId 
         * @param {string} lotId 
         * @param {LotAvailabilityDTO} [lotAvailabilityDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdLotsLotIdPost(releaseId: string, lotId: string, lotAvailabilityDTO?: LotAvailabilityDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LotDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdLotsLotIdPost(releaseId, lotId, lotAvailabilityDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add lots for a release
         * @param {string} releaseId 
         * @param {PostLotsDTO} [postLotsDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdLotsPost(releaseId: string, postLotsDTO?: PostLotsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LotDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdLotsPost(releaseId, postLotsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set lots for a release
         * @param {string} releaseId 
         * @param {PutLotsDTO} [putLotsDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdLotsPut(releaseId: string, putLotsDTO?: PutLotsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LotDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdLotsPut(releaseId, putLotsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start upload of lots
         * @param {string} releaseId 
         * @param {StartLotsUploadDTO} [startLotsUploadDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdLotsStartPost(releaseId: string, startLotsUploadDTO?: StartLotsUploadDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LotDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdLotsStartPost(releaseId, startLotsUploadDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add release particular questions.
         * @param {string} releaseId 
         * @param {Array<ParticularQuestionDTO>} [particularQuestionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdParticularQuestionsPut(releaseId: string, particularQuestionDTO?: Array<ParticularQuestionDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticularQuestionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdParticularQuestionsPut(releaseId, particularQuestionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a project image to a release
         * @param {string} releaseId 
         * @param {FileDTO} [fileDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdProjectImagePut(releaseId: string, fileDTO?: FileDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdProjectImagePut(releaseId, fileDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publish a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdPublishPost(releaseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublishResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdPublishPost(releaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add release signing details.
         * @param {string} releaseId 
         * @param {ReleaseSigningDetailDTO} [releaseSigningDetailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdSigningDetailsPut(releaseId: string, releaseSigningDetailDTO?: ReleaseSigningDetailDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleaseSigningDetailDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdSigningDetailsPut(releaseId, releaseSigningDetailDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get release signing times
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdSigningTimesGet(releaseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReleaseSigningTimeDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdSigningTimesGet(releaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add signing times for a release
         * @param {string} releaseId 
         * @param {PostSigningTimesDTO} [postSigningTimesDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdSigningTimesPost(releaseId: string, postSigningTimesDTO?: PostSigningTimesDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReleaseSigningTimeDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdSigningTimesPost(releaseId, postSigningTimesDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put release signing times.
         * @param {string} releaseId 
         * @param {Array<ReleaseSigningTimeDTO>} [releaseSigningTimeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdSigningTimesPut(releaseId: string, releaseSigningTimeDTO?: Array<ReleaseSigningTimeDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReleaseSigningTimeDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdSigningTimesPut(releaseId, releaseSigningTimeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start upload of release signing times.
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdSigningTimesStartPost(releaseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdSigningTimesStartPost(releaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the release statistics
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReleasesReleaseIdStatisticsGet(releaseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatisticsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReleasesReleaseIdStatisticsGet(releaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReleasesApi - factory interface
 * @export
 */
export const ReleasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReleasesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all releases or a release based on a code.
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesGet(code?: string, options?: any): AxiosPromise<ReleaseDTO> {
            return localVarFp.v1ReleasesGet(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a release.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesPost(options?: any): AxiosPromise<ReleaseDTO> {
            return localVarFp.v1ReleasesPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdCancelPost(releaseId: string, options?: any): AxiosPromise<ReleaseDTO> {
            return localVarFp.v1ReleasesReleaseIdCancelPost(releaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete a release, limited to On Demand Purchasing releases
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdCompletePost(releaseId: string, options?: any): AxiosPromise<ReleaseDTO> {
            return localVarFp.v1ReleasesReleaseIdCompletePost(releaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a release (only available to draft releases)
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdDelete(releaseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ReleasesReleaseIdDelete(releaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add release details.
         * @param {string} releaseId 
         * @param {ReleaseDetailDTO} [releaseDetailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdDetailsPut(releaseId: string, releaseDetailDTO?: ReleaseDetailDTO, options?: any): AxiosPromise<ReleaseDetailDTO> {
            return localVarFp.v1ReleasesReleaseIdDetailsPut(releaseId, releaseDetailDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a developer image to a release
         * @param {string} releaseId 
         * @param {FileDTO} [fileDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdDeveloperImagePut(releaseId: string, fileDTO?: FileDTO, options?: any): AxiosPromise<FileDTO> {
            return localVarFp.v1ReleasesReleaseIdDeveloperImagePut(releaseId, fileDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a file from a release
         * @param {string} releaseId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdDocumentsFileIdDelete(releaseId: string, fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ReleasesReleaseIdDocumentsFileIdDelete(releaseId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a document to a release
         * @param {string} releaseId 
         * @param {FileDTO} [fileDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdDocumentsPost(releaseId: string, fileDTO?: FileDTO, options?: any): AxiosPromise<FileDTO> {
            return localVarFp.v1ReleasesReleaseIdDocumentsPost(releaseId, fileDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Force allocate a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdForceAllocatePost(releaseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ReleasesReleaseIdForceAllocatePost(releaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Force complete a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdForceCompletePost(releaseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ReleasesReleaseIdForceCompletePost(releaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Force publish a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdForcePublishPost(releaseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ReleasesReleaseIdForcePublishPost(releaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Force reminder on a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdForceReminderPost(releaseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ReleasesReleaseIdForceReminderPost(releaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Release based on an ID.
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdGet(releaseId: string, options?: any): AxiosPromise<ReleaseDTO> {
            return localVarFp.v1ReleasesReleaseIdGet(releaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Raise an invoice item (instead of taking immediate payment) and then change the release status to published.
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdInvoicePost(releaseId: string, options?: any): AxiosPromise<ReleaseDTO> {
            return localVarFp.v1ReleasesReleaseIdInvoicePost(releaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change lot availability
         * @param {string} releaseId 
         * @param {string} lotId 
         * @param {LotAvailabilityDTO} [lotAvailabilityDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdLotsLotIdPost(releaseId: string, lotId: string, lotAvailabilityDTO?: LotAvailabilityDTO, options?: any): AxiosPromise<LotDTO> {
            return localVarFp.v1ReleasesReleaseIdLotsLotIdPost(releaseId, lotId, lotAvailabilityDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add lots for a release
         * @param {string} releaseId 
         * @param {PostLotsDTO} [postLotsDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdLotsPost(releaseId: string, postLotsDTO?: PostLotsDTO, options?: any): AxiosPromise<Array<LotDTO>> {
            return localVarFp.v1ReleasesReleaseIdLotsPost(releaseId, postLotsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set lots for a release
         * @param {string} releaseId 
         * @param {PutLotsDTO} [putLotsDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdLotsPut(releaseId: string, putLotsDTO?: PutLotsDTO, options?: any): AxiosPromise<Array<LotDTO>> {
            return localVarFp.v1ReleasesReleaseIdLotsPut(releaseId, putLotsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start upload of lots
         * @param {string} releaseId 
         * @param {StartLotsUploadDTO} [startLotsUploadDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdLotsStartPost(releaseId: string, startLotsUploadDTO?: StartLotsUploadDTO, options?: any): AxiosPromise<Array<LotDTO>> {
            return localVarFp.v1ReleasesReleaseIdLotsStartPost(releaseId, startLotsUploadDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add release particular questions.
         * @param {string} releaseId 
         * @param {Array<ParticularQuestionDTO>} [particularQuestionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdParticularQuestionsPut(releaseId: string, particularQuestionDTO?: Array<ParticularQuestionDTO>, options?: any): AxiosPromise<ParticularQuestionDTO> {
            return localVarFp.v1ReleasesReleaseIdParticularQuestionsPut(releaseId, particularQuestionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a project image to a release
         * @param {string} releaseId 
         * @param {FileDTO} [fileDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdProjectImagePut(releaseId: string, fileDTO?: FileDTO, options?: any): AxiosPromise<FileDTO> {
            return localVarFp.v1ReleasesReleaseIdProjectImagePut(releaseId, fileDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publish a release
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdPublishPost(releaseId: string, options?: any): AxiosPromise<PublishResponseDTO> {
            return localVarFp.v1ReleasesReleaseIdPublishPost(releaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add release signing details.
         * @param {string} releaseId 
         * @param {ReleaseSigningDetailDTO} [releaseSigningDetailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdSigningDetailsPut(releaseId: string, releaseSigningDetailDTO?: ReleaseSigningDetailDTO, options?: any): AxiosPromise<ReleaseSigningDetailDTO> {
            return localVarFp.v1ReleasesReleaseIdSigningDetailsPut(releaseId, releaseSigningDetailDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get release signing times
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdSigningTimesGet(releaseId: string, options?: any): AxiosPromise<Array<ReleaseSigningTimeDTO>> {
            return localVarFp.v1ReleasesReleaseIdSigningTimesGet(releaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add signing times for a release
         * @param {string} releaseId 
         * @param {PostSigningTimesDTO} [postSigningTimesDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdSigningTimesPost(releaseId: string, postSigningTimesDTO?: PostSigningTimesDTO, options?: any): AxiosPromise<Array<ReleaseSigningTimeDTO>> {
            return localVarFp.v1ReleasesReleaseIdSigningTimesPost(releaseId, postSigningTimesDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put release signing times.
         * @param {string} releaseId 
         * @param {Array<ReleaseSigningTimeDTO>} [releaseSigningTimeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdSigningTimesPut(releaseId: string, releaseSigningTimeDTO?: Array<ReleaseSigningTimeDTO>, options?: any): AxiosPromise<Array<ReleaseSigningTimeDTO>> {
            return localVarFp.v1ReleasesReleaseIdSigningTimesPut(releaseId, releaseSigningTimeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start upload of release signing times.
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdSigningTimesStartPost(releaseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ReleasesReleaseIdSigningTimesStartPost(releaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the release statistics
         * @param {string} releaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReleasesReleaseIdStatisticsGet(releaseId: string, options?: any): AxiosPromise<StatisticsDTO> {
            return localVarFp.v1ReleasesReleaseIdStatisticsGet(releaseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReleasesApi - object-oriented interface
 * @export
 * @class ReleasesApi
 * @extends {BaseAPI}
 */
export class ReleasesApi extends BaseAPI {
    /**
     * 
     * @summary Get all releases or a release based on a code.
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesGet(code?: string, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesGet(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a release.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesPost(options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel a release
     * @param {string} releaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdCancelPost(releaseId: string, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdCancelPost(releaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete a release, limited to On Demand Purchasing releases
     * @param {string} releaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdCompletePost(releaseId: string, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdCompletePost(releaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a release (only available to draft releases)
     * @param {string} releaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdDelete(releaseId: string, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdDelete(releaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add release details.
     * @param {string} releaseId 
     * @param {ReleaseDetailDTO} [releaseDetailDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdDetailsPut(releaseId: string, releaseDetailDTO?: ReleaseDetailDTO, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdDetailsPut(releaseId, releaseDetailDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a developer image to a release
     * @param {string} releaseId 
     * @param {FileDTO} [fileDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdDeveloperImagePut(releaseId: string, fileDTO?: FileDTO, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdDeveloperImagePut(releaseId, fileDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a file from a release
     * @param {string} releaseId 
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdDocumentsFileIdDelete(releaseId: string, fileId: string, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdDocumentsFileIdDelete(releaseId, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a document to a release
     * @param {string} releaseId 
     * @param {FileDTO} [fileDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdDocumentsPost(releaseId: string, fileDTO?: FileDTO, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdDocumentsPost(releaseId, fileDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Force allocate a release
     * @param {string} releaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdForceAllocatePost(releaseId: string, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdForceAllocatePost(releaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Force complete a release
     * @param {string} releaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdForceCompletePost(releaseId: string, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdForceCompletePost(releaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Force publish a release
     * @param {string} releaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdForcePublishPost(releaseId: string, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdForcePublishPost(releaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Force reminder on a release
     * @param {string} releaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdForceReminderPost(releaseId: string, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdForceReminderPost(releaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Release based on an ID.
     * @param {string} releaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdGet(releaseId: string, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdGet(releaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Raise an invoice item (instead of taking immediate payment) and then change the release status to published.
     * @param {string} releaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdInvoicePost(releaseId: string, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdInvoicePost(releaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change lot availability
     * @param {string} releaseId 
     * @param {string} lotId 
     * @param {LotAvailabilityDTO} [lotAvailabilityDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdLotsLotIdPost(releaseId: string, lotId: string, lotAvailabilityDTO?: LotAvailabilityDTO, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdLotsLotIdPost(releaseId, lotId, lotAvailabilityDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add lots for a release
     * @param {string} releaseId 
     * @param {PostLotsDTO} [postLotsDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdLotsPost(releaseId: string, postLotsDTO?: PostLotsDTO, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdLotsPost(releaseId, postLotsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set lots for a release
     * @param {string} releaseId 
     * @param {PutLotsDTO} [putLotsDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdLotsPut(releaseId: string, putLotsDTO?: PutLotsDTO, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdLotsPut(releaseId, putLotsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start upload of lots
     * @param {string} releaseId 
     * @param {StartLotsUploadDTO} [startLotsUploadDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdLotsStartPost(releaseId: string, startLotsUploadDTO?: StartLotsUploadDTO, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdLotsStartPost(releaseId, startLotsUploadDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add release particular questions.
     * @param {string} releaseId 
     * @param {Array<ParticularQuestionDTO>} [particularQuestionDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdParticularQuestionsPut(releaseId: string, particularQuestionDTO?: Array<ParticularQuestionDTO>, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdParticularQuestionsPut(releaseId, particularQuestionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a project image to a release
     * @param {string} releaseId 
     * @param {FileDTO} [fileDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdProjectImagePut(releaseId: string, fileDTO?: FileDTO, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdProjectImagePut(releaseId, fileDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publish a release
     * @param {string} releaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdPublishPost(releaseId: string, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdPublishPost(releaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add release signing details.
     * @param {string} releaseId 
     * @param {ReleaseSigningDetailDTO} [releaseSigningDetailDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdSigningDetailsPut(releaseId: string, releaseSigningDetailDTO?: ReleaseSigningDetailDTO, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdSigningDetailsPut(releaseId, releaseSigningDetailDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get release signing times
     * @param {string} releaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdSigningTimesGet(releaseId: string, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdSigningTimesGet(releaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add signing times for a release
     * @param {string} releaseId 
     * @param {PostSigningTimesDTO} [postSigningTimesDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdSigningTimesPost(releaseId: string, postSigningTimesDTO?: PostSigningTimesDTO, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdSigningTimesPost(releaseId, postSigningTimesDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put release signing times.
     * @param {string} releaseId 
     * @param {Array<ReleaseSigningTimeDTO>} [releaseSigningTimeDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdSigningTimesPut(releaseId: string, releaseSigningTimeDTO?: Array<ReleaseSigningTimeDTO>, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdSigningTimesPut(releaseId, releaseSigningTimeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start upload of release signing times.
     * @param {string} releaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdSigningTimesStartPost(releaseId: string, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdSigningTimesStartPost(releaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the release statistics
     * @param {string} releaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public v1ReleasesReleaseIdStatisticsGet(releaseId: string, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).v1ReleasesReleaseIdStatisticsGet(releaseId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SellersApi - axios parameter creator
 * @export
 */
export const SellersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get current seller.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SellersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Sellers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsert a seller.
         * @param {SellerDTO} [sellerDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SellersPut: async (sellerDTO?: SellerDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Sellers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsert a seller.
         * @param {SellerRegisterDTO} [sellerRegisterDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SellersRegisterPost: async (sellerRegisterDTO?: SellerRegisterDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Sellers/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerRegisterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellersApi - functional programming interface
 * @export
 */
export const SellersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get current seller.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SellersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SellersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upsert a seller.
         * @param {SellerDTO} [sellerDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SellersPut(sellerDTO?: SellerDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SellersPut(sellerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upsert a seller.
         * @param {SellerRegisterDTO} [sellerRegisterDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SellersRegisterPost(sellerRegisterDTO?: SellerRegisterDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Seller>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SellersRegisterPost(sellerRegisterDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SellersApi - factory interface
 * @export
 */
export const SellersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get current seller.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SellersGet(options?: any): AxiosPromise<SellerDTO> {
            return localVarFp.v1SellersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsert a seller.
         * @param {SellerDTO} [sellerDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SellersPut(sellerDTO?: SellerDTO, options?: any): AxiosPromise<SellerDTO> {
            return localVarFp.v1SellersPut(sellerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsert a seller.
         * @param {SellerRegisterDTO} [sellerRegisterDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SellersRegisterPost(sellerRegisterDTO?: SellerRegisterDTO, options?: any): AxiosPromise<Seller> {
            return localVarFp.v1SellersRegisterPost(sellerRegisterDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SellersApi - object-oriented interface
 * @export
 * @class SellersApi
 * @extends {BaseAPI}
 */
export class SellersApi extends BaseAPI {
    /**
     * 
     * @summary Get current seller.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersApi
     */
    public v1SellersGet(options?: AxiosRequestConfig) {
        return SellersApiFp(this.configuration).v1SellersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsert a seller.
     * @param {SellerDTO} [sellerDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersApi
     */
    public v1SellersPut(sellerDTO?: SellerDTO, options?: AxiosRequestConfig) {
        return SellersApiFp(this.configuration).v1SellersPut(sellerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsert a seller.
     * @param {SellerRegisterDTO} [sellerRegisterDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersApi
     */
    public v1SellersRegisterPost(sellerRegisterDTO?: SellerRegisterDTO, options?: AxiosRequestConfig) {
        return SellersApiFp(this.configuration).v1SellersRegisterPost(sellerRegisterDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StripeApi - axios parameter creator
 * @export
 */
export const StripeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StripeWebhookPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Stripe/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StripeApi - functional programming interface
 * @export
 */
export const StripeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StripeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1StripeWebhookPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1StripeWebhookPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StripeApi - factory interface
 * @export
 */
export const StripeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StripeApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StripeWebhookPost(options?: any): AxiosPromise<void> {
            return localVarFp.v1StripeWebhookPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StripeApi - object-oriented interface
 * @export
 * @class StripeApi
 * @extends {BaseAPI}
 */
export class StripeApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public v1StripeWebhookPost(options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).v1StripeWebhookPost(options).then((request) => request(this.axios, this.basePath));
    }
}


