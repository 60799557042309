import {
  ReleasesApi,
  ApplicationsApi,
  StripeApi,
  SellersApi,
  PurchasesApi,
} from '../api-client'
import axios from './axios'

const initialisedAPIs = {
  release: new ReleasesApi(undefined, process.env.VUE_APP_API_URL, axios),
  application: new ApplicationsApi(
    undefined,
    process.env.VUE_APP_API_URL,
    axios,
  ),
  stripe: new StripeApi(undefined, process.env.VUE_APP_API_URL, axios),
  seller: new SellersApi(undefined, process.env.VUE_APP_API_URL, axios),
  purchase: new PurchasesApi(undefined, process.env.VUE_APP_API_URL, axios),
}

export default initialisedAPIs
