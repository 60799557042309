<template>
  <div class="text-center pa-12">
    <p class="text-h1">Uhoh.</p>
    <p class="body-1 py-6">This page doesn’t exist, sorry about that.</p>
    <v-img class="mx-auto" src="/img/error-not-found.svg" max-width="480px" />
  </div>
</template>

<script>
export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  head: {
    title() {
      return {
        inner: this.error.statusCode === 404 ? '404 Not Found' : 'Error',
      }
    },
  },
}
</script>
