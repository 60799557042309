import { GetterTree, ActionTree, MutationTree } from 'vuex'
import {
  ISignUpResult,
  CognitoUser,
  CognitoUserSession,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js'
import Amplify, { Auth } from 'aws-amplify'
import { SignUpParams, SignInOpts } from '@aws-amplify/auth/lib-esm/types'

// Getters
export const IS_LOGGED_IN = 'isLoggedIn'
export const IS_CONFIRMED = 'isConfirmed'
export const IS_SESSION_VALID = 'isSessionValid'
export const SESSION = 'session'
export const USER_SUB = 'userSub'
export const USER = 'user'
export const USERNAME = 'username'
export const USER_ATTRIBUTES = 'userAttributes'
export const USER_ATTRIBUTE = 'userAttribute'
export const USER_GROUPS = 'userGroups'
export const ACCESS_TOKEN = 'accessToken'
export const ID_TOKEN = 'idToken'

// Mutations
export const SET_USER = 'setUser'

// Actions
export const FETCH_USER = 'fetchUser'
export const FETCH_SESSION = 'fetchSession'
export const FETCH_ID_TOKEN = 'fetchIdToken'
export const FETCH_ACCESS_TOKEN = 'fetchAccessToken'
export const SIGN_IN = 'signInUser'
export const COMPLETE_NEW_PASSWORD = 'completeNewPassword'
export const ANSWER_CUSTOM_CHALLENGE = 'answerCustomChallenge'
export const REGISTER_USER = 'registerUser'
export const CONFIRM_USER = 'confirmUser'
export const RESEND_CONFIRMATION = 'resendConfirmation'
export const FORGOT_PASSWORD = 'forgotPassword'
export const CHANGE_PASSWORD = 'changePassword'
export const SIGN_OUT = 'signOut'
export const INIT = 'init'

interface RootState {
  user: CognitoUser | null
  session: CognitoUserSession | null
}

export const state = (): RootState => ({
  session: null,
  user: null,
})

// Getters
export const getters: GetterTree<RootState, RootState> = {
  [IS_LOGGED_IN]({ session, user }) {
    if (!session || !user) return false
    const accessToken = session.getAccessToken()
    if (!accessToken) return false
    const hasToken = !!accessToken.getJwtToken()
    const isActive = new Date(accessToken.payload.exp * 1000) > new Date()
    const isMe = accessToken.payload.username === user.getUsername()
    return hasToken && isActive && isMe
  },
  [IS_CONFIRMED]({ session }) {
    return session?.getIdToken()?.decodePayload()?.email_verified ?? false
  },
  [IS_SESSION_VALID]({ session }) {
    return session?.isValid() ?? false
  },
  [SESSION]({ session }) {
    return session
  },
  [USER]({ user }) {
    return user
  },
  [USERNAME]({ user }) {
    return user?.getUsername()
  },
  [USER_ATTRIBUTES]({ user }): Promise<CognitoUserAttribute[] | null> {
    if (!user) return Promise.resolve(null)
    return new Promise((resolve, reject) =>
      user.getUserAttributes((err, result) => {
        if (err) reject(err)
        else if (!result) reject(new Error('No result'))
        else resolve(result)
      }),
    )
  },
  [USER_GROUPS]({ session }) {
    return session?.getAccessToken().decodePayload()['cognito:groups']
  },
  [ACCESS_TOKEN]({ session }) {
    return session?.getAccessToken().getJwtToken()
  },
  [ID_TOKEN]({ session }) {
    return session?.getIdToken().getJwtToken()
  },
}

// Mutations
export const mutations: MutationTree<RootState> = {
  [SET_USER](state, user) {
    state.user = user
    state.session = user ? user.getSignInUserSession() : null
  },
}

// Actions
export const actions: ActionTree<RootState, RootState> = {
  [FETCH_USER]({ commit }) {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          commit(SET_USER, user)
          resolve(user)
        })
        .catch(reject)
    })
  },
  [FETCH_SESSION]({ commit }) {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          Auth.currentUserPoolUser()
            .then((user: CognitoUser) => {
              commit(SET_USER, user)
              resolve(session)
            })
            .catch(reject)
        })
        .catch(reject)
    })
  },
  [FETCH_ID_TOKEN]() {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          resolve(session.getIdToken().getJwtToken())
        })
        .catch(reject)
    })
  },
  [FETCH_ACCESS_TOKEN]() {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          resolve(session.getAccessToken().getJwtToken())
        })
        .catch(reject)
    })
  },
  [SIGN_IN]({ commit }, data: SignInOpts) {
    return new Promise((resolve, reject) => {
      Auth.signIn(data.username, data.password)
        .then((user: CognitoUser) => {
          commit(SET_USER, user)
          resolve(user)
        })
        .catch(reject)
    })
  },
  [COMPLETE_NEW_PASSWORD]({ state, commit }, data) {
    return new Promise((resolve, reject) => {
      Auth.completeNewPassword(state.user, data.password)
        .then((user: CognitoUser) => {
          commit(SET_USER, user)
          resolve(user)
        })
        .catch(reject)
    })
  },
  [ANSWER_CUSTOM_CHALLENGE]({ state, commit }, data) {
    return new Promise((resolve, reject) => {
      Auth.sendCustomChallengeAnswer(state.user, data.answer)
        .then((user: CognitoUser) => {
          commit(SET_USER, user)
          resolve(user)
        })
        .catch(reject)
    })
  },
  [REGISTER_USER]({ commit }, data: SignUpParams) {
    // Not used - backend does this
    return new Promise((resolve, reject) => {
      Auth.signUp(data)
        .then((res: ISignUpResult) => {
          commit(SET_USER, res.user)
          resolve(res)
        })
        .catch(reject)
    })
  },
  [CONFIRM_USER](_, { username, code }) {
    return new Promise((resolve, reject) => {
      Auth.confirmSignUp(username, code)
        .then(resolve)
        .catch(reject)
    })
  },
  [RESEND_CONFIRMATION](_, username) {
    return new Promise((resolve, reject) => {
      Auth.resendSignUp(username)
        .then(resolve)
        .catch(reject)
    })
  },
  [FORGOT_PASSWORD](_, email) {
    return new Promise((resolve, reject) => {
      Auth.forgotPassword(email)
        .then(resolve)
        .catch(reject)
    })
  },
  [CHANGE_PASSWORD](_, data) {
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(data.username, data.code, data.newPassword)
        .then(resolve)
        .catch(reject)
    })
  },
  [SIGN_OUT]({ commit }) {
    return new Promise((resolve, reject) => {
      Auth.signOut()
        .then(result => {
          commit(SET_USER, null)
          resolve(result)
        })
        .catch(reject)
    })
  },
  [INIT](_, data) {
    Amplify.configure({ Auth: data })
  },
  [USER_ATTRIBUTE]({ state }, attributeName: string): Promise<string | null> {
    if (!state.user) return Promise.resolve(null)
    return new Promise((resolve, reject) =>
      state.user!.getUserAttributes(
        (err, result: CognitoUserAttribute[] | undefined) => {
          if (err) reject(err)
          else if (!result) reject(new Error('No result'))
          else
            resolve(
              result.find(attr => attr.Name === attributeName)?.Value ?? null,
            )
        },
      ),
    )
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
