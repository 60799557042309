import Vue from 'vue'
import { ValidationProvider, extend } from 'vee-validate'
import {
  required,
  confirmed,
  email,
  min,
  max,
  max_value as maxValue,
  min_value as minValue,
  is_not as isNot,
  digits,
} from 'vee-validate/dist/rules'
import PhoneNumber from 'awesome-phonenumber'
import validateUuid from 'uuid-validate'
import { states } from '@/modules/config'
import { removeSpaces } from '@/modules/stringUtils'

const PhoneRegionCode = 'AU'
const PhoneExpectedCountryCode = PhoneNumber.getCountryCodeForRegionCode(
  PhoneRegionCode,
)

export default function init() {
  // release codes are expected to be exactly 5 alphanumeric characters
  extend('releaseCode', value =>
    /^[0-9A-Za-z]{5}$/.test(value) ? true : 'Not a valid release code',
  )

  // applications IDs and keys are just UUIDs
  extend(
    'applicationId',
    value => validateUuid(value) || 'Not a valid application ID',
  )
  extend(
    'applicationKey',
    value => validateUuid(value) || 'Not a valid application key',
  )

  // expects a string defining a comma separated list
  extend('excludes', (value, excludeList) => {
    return excludeList.includes(value.toString())
      ? 'Cannot be one of ' + excludeList.join(', ')
      : true
  })

  // just validate that it is checked
  extend('checkbox', value => {
    return value
  })

  // postcodes in Australia are expected to be exactly 4 digits
  // leading zeroes are allowed
  extend('postcode', value =>
    /^[\d]{4}$/.test(value) ? true : 'Should be 4 digits',
  )

  extend('state', value => {
    const statePostalCodes = states.map(s => s.postal)
    return statePostalCodes.includes(value) ? true : 'Not a state'
  })

  extend('passport', () => {
    // TODO: investigate proper regex
    return true
    // return /^[a-zA-Z]*\s?\d+$/.test(value)
    //   ? true
    //   : 'Not a valid passport number'
  })
  extend('driversLicence', value => {
    // TODO: perhaps expand on this in future
    return /^\d{4,10}[a-zA-Z]{0,2}$/.test(value)
      ? true
      : 'Not a valid licence number'
  })

  extend('required', {
    message(fieldName) {
      return `${fieldName} is required`
    },
    ...required,
  })

  extend('confirmed', {
    message(fieldName) {
      return `${fieldName} does not match`
    },
    ...confirmed,
  })

  extend('email', email)
  extend('min', {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    message(_fieldName: string, params: Record<string, any>) {
      return `Must be at least ${params.length} characters`
    },
    ...min,
  })
  extend('max', {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    message(_fieldName: string, params: Record<string, any>) {
      return `Must be at most ${params.length} characters`
    },
    ...max,
  })
  extend('min_value', minValue)
  extend('max_value', maxValue)
  extend('is_not', isNot)

  extend('phone', {
    message() {
      return 'Not a valid phone number'
    },
    validate(value: string) {
      return new Promise(resolve => {
        const phone = new PhoneNumber(value, PhoneRegionCode)
        resolve({
          valid:
            phone.isValid() &&
            phone.getCountryCode() == PhoneExpectedCountryCode,
        })
      })
    },
  })

  extend('mobile', {
    message() {
      return 'Not a valid mobile number'
    },
    validate(value: string) {
      return new Promise(resolve => {
        const phone = new PhoneNumber(value, PhoneRegionCode)
        resolve({
          valid:
            phone.isValid() &&
            phone.isMobile() &&
            phone.getCountryCode() == PhoneExpectedCountryCode,
        })
      })
    },
  })

  extend('url', {
    validate(value) {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol (optional)
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$', // fragment locator
        'i',
      )
      return pattern.test(value)
    },
    message: 'Not a valid URL (must include http:// or https://)',
  })

  extend('has_uppercase', {
    message(fieldName) {
      return `${fieldName} must contain an uppercase letter`
    },
    validate(v: string) {
      return {
        valid: /[A-Z]/.test(v),
      }
    },
  })
  extend('has_lowercase', {
    message(fieldName) {
      return `${fieldName} must contain a lowercase letter`
    },
    validate(v: string) {
      return {
        valid: /[a-z]/.test(v),
      }
    },
  })
  extend('has_digit', {
    message(fieldName) {
      return `${fieldName} must contain a digit`
    },
    validate(v: string) {
      return {
        valid: /[0-9]/.test(v),
      }
    },
  })
  extend('has_special', {
    message(fieldName) {
      return `${fieldName} must contain one of: ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ `
    },
    validate(v: string) {
      return {
        valid: /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/.test(v),
      }
    },
  })

  function isAbn(value: string): boolean {
    // https://abr.business.gov.au/Help/AbnFormat
    const ABN_LENGTH = 11
    const WEIGHTS = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]

    const isDigitsCorrect = digits.validate(value, { length: ABN_LENGTH })
    if (isDigitsCorrect !== true) {
      return isDigitsCorrect
    }

    const parsedDigits = [parseInt(value.substring(0, 1)) - 1].concat(
      value
        .slice(1)
        .split('')
        .map(v => parseInt(v)),
    )
    const weightedSum = parsedDigits.reduce(
      (acc, d, i) => acc + d * WEIGHTS[i],
      0,
    )

    return weightedSum % 89 === 0
  }

  function isAcn(value: string): boolean {
    // https://asic.gov.au/for-business/registering-a-company/steps-to-register-a-company/australian-company-numbers/australian-company-number-digit-check/
    const ACN_LENGTH = 9
    const WEIGHTS = [8, 7, 6, 5, 4, 3, 2, 1]

    const isDigitsCorrect = digits.validate(value, { length: ACN_LENGTH })
    if (isDigitsCorrect !== true) {
      return isDigitsCorrect
    }

    const parsedDigits = value
      .substring(0, ACN_LENGTH - 1)
      .split('')
      .map(v => parseInt(v))
    const weightedSum = parsedDigits.reduce(
      (acc, d, i) => acc + d * WEIGHTS[i],
      0,
    )

    const remainder = weightedSum % 10
    const complement = 10 - remainder
    const finalDigit = parseInt(value) % 10

    return complement === finalDigit
  }

  extend('check_abn_or_acn', (value: string) => {
    if (isAbn(removeSpaces(value))) {
      return true
    }
    if (isAcn(removeSpaces(value))) {
      return true
    }
    return 'Not a valid ABN or ACN'
  })

  // Register it globally
  Vue.component('ValidationProvider', ValidationProvider)
}
