import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import router from '@/modules/router'

export default function() {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID,
    debug: {
      enabled: process.env.NODE_ENV === 'development',
      sendHitTask: process.env.NODE_ENV === 'development',
    },
    router,
  })
}
