import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import vuetify from './modules/vuetify'
import router from './modules/router'
import store from './modules/store'
import setUpValidation from './modules/validation'
import setUpAuth from './modules/auth'
import setUpSentry from './modules/sentry'
import axios from '@/modules/axios'
import api from '@/modules/api'
import setUpAnalytics from '@/modules/analytics'
import VueHead from 'vue-head'
import vueHeadConfig from '@/modules/vue-head'
import VueClipboard from 'vue-clipboard2'
import VueApexCharts from 'vue-apexcharts'

import '@/styles/global.scss'

Vue.config.productionTip = false
Vue.use(VueHead, vueHeadConfig)
Vue.use(VueClipboard)
Vue.use(VueApexCharts)
Vue.component('ApexChart', VueApexCharts)

setUpValidation()
setUpAnalytics()
setUpSentry()
setUpAuth().then(() => {
  Vue.prototype.$axios = axios
  Vue.prototype.$api = api

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  }).$mount('#app')
})
