import Vue from 'vue'
import Vuex from 'vuex'
import auth, { state as authState } from '@/store/auth'
import snackbar from '@/store/snackbar'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ...authState(),
  },
  mutations: {},
  actions: {},
  modules: {
    auth,
    snackbar,
  },
})
