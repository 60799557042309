import { PRODUCT_NAME } from '@/modules/config'

export default {
  inner: '',
  separator: '|',
  complement: PRODUCT_NAME,
  meta: [
    { name: 'application-name', content: PRODUCT_NAME },
    {
      name: 'description',
      content: 'Buy & sell land without the queues',
      id: 'desc',
    }, // id to replace intead of create element
  ],
}
