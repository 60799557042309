import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import store from './store'
import {
  IS_LOGGED_IN,
  ID_TOKEN,
  FETCH_SESSION,
  IS_SESSION_VALID,
} from '@/store/auth'
import router from './router'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
})

type HasLocation = {
  location?: Location
}

instance.interceptors.response.use(
  (res: AxiosResponse) => {
    // happy response :)
    return res
  },
  (err: AxiosError) => {
    if (
      err?.response?.status === 403 &&
      err?.response.data &&
      (err?.response.data as HasLocation).location
    ) {
      window.location = (err.response.data as HasLocation).location!
    } else if (err.response && [401, 403].includes(err.response.status)) {
      router.push('/sign-in')
    } else {
      throw err
    }
  },
)

instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  // users internet can be slow to upload large files
  if (config.url?.includes('attachmentsbucket')) config.timeout = 300000 // 5 minutes

  if (store.getters[IS_LOGGED_IN] && config.url!.includes(config.baseURL!)) {
    // refresh session if needed
    if (!store.getters[IS_SESSION_VALID]) await store.dispatch(FETCH_SESSION)

    // init headers if missing
    if (!config.headers) config.headers = {}

    // add id token to auth header
    config.headers['Authorization'] = `Bearer ${store.getters[ID_TOKEN]}`
  }
  return config
})

export default instance
